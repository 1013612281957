import { DatePicker as AntdDatePicker } from 'antd'
import classNames from 'classnames'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { forwardRef, useCallback, useMemo, useRef, useState } from 'react'

import { STitleWrapp, SWrap } from './styles/DatePicker.styled'

export interface IDatePickerProps {
  className?: string
  placeholder?: string
  error?: boolean
  disabled?: boolean
  value?: string | Dayjs
  onFocus?: () => void
  onBlur?: () => void
  onChange?: (value: string | null) => void
  disabledDate?: (current: Dayjs) => boolean
  title?: string
}

const defaultFormat = 'DD.MM.YYYY'

const DatePicker = (
  {
    placeholder,
    className,
    error,
    disabled,
    value,
    disabledDate,
    onFocus,
    onBlur,
    onChange,
    title,
  }: IDatePickerProps,
  ref: any,
) => {
  const transformDate = dayjs(value)
  const wrapRef = useRef<HTMLDivElement>(null)

  const [, setInternalValue] = useState<any>()
  const [focused, setFocused] = useState(false)

  const isFocused = focused

  const internalClassName = useMemo(
    () =>
      classNames(className, {
        focused: isFocused,
        error,
        disabled,
      }),
    [className, disabled, error, isFocused],
  )

  const handleOnChange = useCallback(
    (newValue: any) => {
      const transormDateFront = newValue
        ? dayjs(newValue).format('DD.MM.YYYY')
        : null
      onChange && onChange(transormDateFront)
      setInternalValue(transormDateFront)
    },
    [onChange],
  )

  const handleOnFocus = useCallback(() => {
    onFocus && onFocus()
    setFocused(true)
  }, [onFocus])

  const handleOnBlur = useCallback(() => {
    onBlur && onBlur()
    setFocused(false)
  }, [onBlur])

  return (
    <SWrap ref={wrapRef} className={internalClassName}>
      <STitleWrapp>{title}</STitleWrapp>
      <AntdDatePicker
        placeholder={placeholder}
        ref={ref}
        format={defaultFormat}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        disabled={disabled}
        disabledDate={disabledDate}
        defaultValue={transformDate}
      />
    </SWrap>
  )
}

export default forwardRef<any, IDatePickerProps>(DatePicker)
