// eslint-disable-next-line import/no-named-default
import { default as InternalDatePicker } from './DatePicker'
import { RangeDatePicker } from './RangeDatePicker'

export type CompoundedComponent = typeof InternalDatePicker & {
  Range: typeof RangeDatePicker
}

export const DatePicker = InternalDatePicker as CompoundedComponent
DatePicker.Range = RangeDatePicker

export type { IDatePickerProps } from './DatePicker'
export type { IRangeDatePickerProps, RangeValue } from './RangeDatePicker'
