import styled from 'styled-components'

import { styleMixins } from '../../../style'

const { defaultTextStyle, defaultTitleStyle } = styleMixins.text
const { getSpacing } = styleMixins.offset

export const SCardWrapper = styled.div<{
  danger?: boolean
  disabled?: boolean
}>`
  display: flex;
  height: auto;
  justify-content: space-between;
  flex-direction: row;
  padding: ${getSpacing(3)};
  border-radius: ${(p) => p.theme.decoration.borderRadius.smaller};
  background-color: ${(p) => p.theme.colors.grey_000};
  box-shadow: ${(p) => p.theme.decoration.boxShadow.default};
  cursor: pointer;
  min-height: 150px;
  gap: ${getSpacing(1)};
  border: 1px solid
    ${(p) => (p.danger ? p.theme.colors.red_500 : p.theme.colors.grey_000)};
  background-color: ${(p) =>
    p.disabled ? p.theme.colors.grey_300 : p.theme.colors.grey_000};

  &.teachers {
    max-height: 150px;
  }
  @media ${(p) => p.theme.breakpoints.desktop} {
    width: 100%;
    gap: 0;
  }

  @media ${(p) => p.theme.breakpoints.mobile} {
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 280px;
  }
`

export const SIconWrapp = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`

export const TypographyHeader = styled.div`
  display: flex;
  ${defaultTitleStyle(7)};
  margin: 0;
  color: ${(p) => p.theme.colors.grey_600};

  @media ${(p) => p.theme.breakpoints.mobile} {
    padding: 0;
  }
`

export const TypographyPreHeader = styled.p`
  display: flex;
  ${defaultTextStyle(2)};
  margin: 0;
  color: ${(p) => p.theme.colors.grey_600};
  padding: ${getSpacing([0, 4, 0, 0])};
`

export const TypographyPreBody = styled.p`
  display: flex;
  ${defaultTextStyle(9)}
  margin: 0;
  color: ${(p) => p.theme.colors.grey_500};
  padding: ${getSpacing([0, 4, 0, 0])};
`

export const TypographyHeaderProfile = styled.div`
  ${defaultTitleStyle(7)};
  margin: 0;
  max-width: 300px;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(p) => p.theme.colors.grey_600};
`

export const TypographyBodyProfile = styled.div`
  display: flex;
  flex-direction: column;
  ${defaultTextStyle(1)};
  margin: 0;
  padding: ${getSpacing([3, 0, 0, 0])};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(p) => p.theme.colors.grey_600};
`

export const TypographyBody = styled.p`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  margin: 0;
  ${defaultTextStyle(7)};
  color: ${(p) => p.theme.colors.grey_500};

  img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 170px;
    object-fit: cover;
    border-radius: ${(p) => p.theme.decoration.borderRadius.default};
  }
`

export const TypographyBodyAchievements = styled.p`
  ${defaultTitleStyle(4)}
  margin: 0;
  padding: 0;
  color: ${(p) => p.theme.colors.grey_500};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
`

export const TypographyHeaderAchievements = styled.p`
  ${defaultTextStyle(2)}
  display: flex;
  margin: 0;
  color: ${(p) => p.theme.colors.grey_600};
  padding: ${getSpacing([0, 4, 0, 0])};
`

export const TypographyFooter = styled.div`
  display: -webkit-box;
  ${defaultTextStyle(5)};
  color: ${(p) => p.theme.colors.grey_450};
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 30px;
`

export const SBodyWrapp = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  max-width: 300px;
  img {
    justify-content: center;

    @media ${(p) => p.theme.breakpoints.mobile} {
      justify-content: center;
      align-items: center;
      max-width: 100%;
    }

    @media ${(p) => p.theme.breakpoints.desktop} {
      justify-content: center;
      align-items: center;
    }
  }

  @media ${(p) => p.theme.breakpoints.mobile} {
    justify-content: flex-start;
  }
`

export const STitleWrapp = styled.div`
  display: flex;
`

export const SPreHeaderWrapp = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
`

export const SArrowWrapp = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: auto;
`

export const SDangerInfo = styled.p`
  width: 100%;
  color: ${(p) => p.theme.colors.red_500};
`

export const SFooterWrapp = styled.div`
  display: flex;
  width: 100%;
  color: ${(props) => props.theme.colors.grey_400};
  align-items: flex-end;

  @media ${(p) => p.theme.breakpoints.tablet} {
    max-width: 280px;
    padding-right: ${getSpacing(2)};
  }

  @media ${(p) => p.theme.breakpoints.mobile} {
    padding-right: 0;
  }
`

export const SInfoWrapCard = styled.div`
  display: flex;
  height: auto;
  gap: ${getSpacing(2)};
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-width: 300px;

  @media ${(p) => p.theme.breakpoints.mobile} {
    min-width: 0;
  }
`

export const SIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
