import { DatePicker as AntdDatePicker } from 'antd'
import classNames from 'classnames'
import type { Dayjs } from 'dayjs'
import type { FC } from 'react'
import { useCallback, useMemo, useRef, useState } from 'react'

import { SPlaceholder, SWrap } from './styles/DatePicker.styled'

export declare type Nullable<T> = T | null

export type RangeValue = Nullable<[Nullable<Dayjs>, Nullable<Dayjs>]>

export interface IRangeDatePickerProps {
  className?: string
  placeholder?: string
  error?: boolean
  disabled?: boolean
  value?: RangeValue
  showTime?: boolean
  onFocus?: () => void
  onBlur?: () => void
  onChange?: (value: RangeValue) => void
  disabledDate?: (current: Dayjs) => boolean
}

export const RangeDatePicker: FC<IRangeDatePickerProps> = ({
  className,
  placeholder,
  error,
  disabled,
  value,
  disabledDate,
  showTime,
  onFocus,
  onBlur,
  onChange,
}) => {
  const wrapRef = useRef<HTMLDivElement>(null)

  const [internalValue, setInternalValue] = useState<RangeValue>()
  const [focused, setFocused] = useState(false)

  const currentValue = value || internalValue
  const isFocus = focused || !!currentValue
  const formatDate = showTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'

  const internalClassName = useMemo(
    () =>
      classNames(className, {
        focused: isFocus,
        error,
        disabled,
      }),
    [className, disabled, error, isFocus],
  )

  const handleOnChange = useCallback(
    (newValue: RangeValue) => {
      onChange && onChange(newValue)
      setInternalValue(newValue)
    },
    [onChange],
  )

  const handleOnFocus = useCallback(() => {
    onFocus && onFocus()
    setFocused(true)
  }, [onFocus])

  const handleOnBlur = useCallback(() => {
    onBlur && onBlur()
    setFocused(false)
  }, [onBlur])

  return (
    <SWrap ref={wrapRef} className={internalClassName}>
      <AntdDatePicker.RangePicker
        value={currentValue}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        disabled={disabled}
        format={formatDate}
        showTime={showTime}
        disabledDate={disabledDate}
      />
      {!!placeholder && <SPlaceholder>{placeholder}</SPlaceholder>}
    </SWrap>
  )
}
