import type { QueryKeyENUM } from 'components/atoms/Filters/ApplyFilters.model'
import type { ItemType } from 'components/Filters/ApplyFilters.model'
import type { Dayjs } from 'dayjs'
import { routeTitleMap } from 'registrators/registrators'

export const queryParser = (filters: Record<QueryKeyENUM, ItemType>) => {
  const filteredQueryList = filters
    ? Object.entries(filters).filter((item) => !!item[1])
    : null
  if (filteredQueryList?.length) {
    const query = {} as Record<QueryKeyENUM, number>
    filteredQueryList.forEach(([key, item]) => {
      // @ts-ignore
      query[key] = item.value
    })
    return query
  }
  return null
}

export const revertDayJSToFormat = (
  essence: Dayjs | string,
  format: string,
) => {
  if (typeof essence === 'object') {
    return essence.format(format)
  }
  return essence
}

export const getStaticPaths = (pathname: string) => {
  const regexp = /(\/[a-zA-Z]+)/g
  return pathname.match(regexp)
}

export const getBreadcrumbs = (pathname: string, name: string) => {
  const staticPaths = getStaticPaths(pathname)

  if (!staticPaths || !staticPaths.length) {
    return undefined
  }

  const tempBreadcrumbs = staticPaths.map((route) => ({
    label: routeTitleMap[route as keyof typeof routeTitleMap],
    href: route,
  }))

  return [
    ...tempBreadcrumbs,
    {
      label: name,
      href: '',
    },
  ]
}

export const joinNonEmptyStrings = (
  stringArray: Array<string | null | undefined>,
) => stringArray.filter(Boolean).join(' ')
