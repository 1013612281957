import { styleMixins } from 'style'
import { opacityColor } from 'style/styleMixins/color'
import { defaultTitleStyle } from 'style/styleMixins/text'
import styled from 'styled-components'

const { defaultTextStyle } = styleMixins.text
const { getSpacing } = styleMixins.offset

export const SPlaceholder = styled.span`
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: 18px;
  left: 16px;
  transition: all 0.2s ease-in-out;
  color: ${(p) => p.theme.colors.blue_500};
  ${defaultTextStyle(2)};
`

export const SWrap = styled.div`
  position: relative;

  .ant-picker {
    height: 36px;
    width: 100%;
    ${defaultTextStyle(1)};
    outline: none;
    box-shadow: none;
    position: relative;
    padding: ${getSpacing([3.25, 6.25, 0.75, 1.75])};
    border-radius: ${(p) => p.theme.decoration.borderRadius.small};
    background-color: ${(p) => p.theme.colors.grey_000};
    border: 1px solid ${(p) => p.theme.colors.grey_400};

    :hover {
      border: 1px solid ${(p) => p.theme.colors.grey_600};
    }

    &-input {
      position: initial;

      & > input {
        cursor: text;
        top: -10px;
        color: ${(p) => p.theme.colors.grey_500};
        ${defaultTextStyle(2)};

        &::placeholder {
          transition: all 0.2s;
          color: transparent;
        }
      }
    }

    &-clear{
      position: absolute;
      top: 50%;
      right: 16px;
      margin-right: 30px;
      transform: translateY(-50%);
      color: ${(p) => p.theme.colors.grey_400};
      
      & svg {
        width: 20px;
        height: 20px;
        
        &:hover{
          color: ${(p) => p.theme.colors.grey_400};
        }
      }
    }
    &-suffix {
      position: absolute;
      margin: 0;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      color: ${(p) => p.theme.colors.blue_500};

      & svg {
        width: 20px;
        height: 20px;
      }
    }

    &-range {
      &-separator {
        display: none;

        & svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    &-active-bar {
      display: none;
    }

    &-status-error {
        border: 1px solid ${(p) => p.theme.colors.red_500};
      }
    }

    &.focused {
      & .ant-picker {
        border-color: ${(p) => p.theme.colors.grey_500};
        background-color: ${(p) => p.theme.colors.grey_000};
        outline: 2px solid ${(p) => opacityColor(p.theme.colors.blue_600, 0.5)};

        &-input {
          & > input {
            &::placeholder {
              color: ${(p) => p.theme.colors.grey_600};
            }
          }
        }

        &-suffix {
          color: ${(p) => p.theme.colors.blue_500};
          cursor: pointer;
        }

        &-range-separator {
          display: flex;
        }
      }

      & ${SPlaceholder} {
        top: 8px;
        color: ${(p) => p.theme.colors.grey_600};
        ${defaultTextStyle(3)};
      }
    }
    

    &.disabled {
      .ant-picker {
        border-color: transparent;
        background-color: ${(p) => p.theme.colors.grey_200};

        &-input > input {
          color: ${(p) => p.theme.colors.grey_400};
        }

        &-suffix {
          color: ${(p) => p.theme.colors.blue_500};
        }
      }
    }
  }
`

export const STitleWrapp = styled.p`
  font-family: 'Golos', serif !important;
  font-style: normal;
  ${defaultTitleStyle(8)};
  color: ${(p) => p.theme.colors.grey_600};
`
