import type { FC } from 'react'
import { useCallback } from 'react'

import type { IPresentationCardProps } from './PresentationCard.model'
import {
  SBodyWrapp,
  SCardWrapper,
  SFooterWrapp,
  SIconWrapp,
  SIconWrapper,
  SInfoWrapCard,
  SPreHeaderWrapp,
  STitleWrapp,
} from './PresentationCard.styled'

export const PresentationCard: FC<IPresentationCardProps> = ({
  header,
  body,
  footer,
  onClick,
  callbackData,
  callbackForData,
  icon,
  cardName,
  preheader,
  prebody,
  danger,
  disabled,
}) => {
  const handleCardClick = useCallback(() => {
    if (onClick && !disabled) {
      onClick()
    }
    if (callbackData && callbackForData) {
      callbackForData(callbackData)
    }
  }, [callbackData, callbackForData, disabled, onClick])

  return (
    <SCardWrapper
      className={`${cardName} bodyCard`}
      onClick={handleCardClick}
      danger={danger}
      disabled={disabled}>
      <SInfoWrapCard className="cardContent">
        {preheader && <SPreHeaderWrapp>{preheader}</SPreHeaderWrapp>}
        {header && <STitleWrapp>{header}</STitleWrapp>}
        {prebody && <SPreHeaderWrapp>{prebody}</SPreHeaderWrapp>}
        {body && <SBodyWrapp>{body}</SBodyWrapp>}
        {footer && <SFooterWrapp>{footer}</SFooterWrapp>}
      </SInfoWrapCard>
      <SIconWrapper>{icon && <SIconWrapp>{icon}</SIconWrapp>}</SIconWrapper>
    </SCardWrapper>
  )
}
